import axios from "axios";

export const listAllPda= () => {
  return axios.get("/maintenance/pda/listAll");
};

export const list = (params, page, pageSize) => {
  return axios.get("/maintenance/pda/list", {
    params: {
      ...params,
      page,
      pageSize,
    },
  });
};

export const save = vo => {
  return axios.post("/maintenance/pda/add", vo);
};

export const detail = id => {
  return axios.get(`/maintenance/pda/${id}/detail`);
};

export const edit = (id, vo) => {
  return axios.put(`/maintenance/pda/${id}/edit`, vo);
};

export const deletePda = id => {
  return axios.delete(`/maintenance/pda/${id}/del`);
};
